import React, { useCallback } from 'react';
import { Divider, Drawer, IconButton, List, ListItem, ListItemText, ListSubheader, useTheme } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';

const SubHeader: React.FC<{ onClickInc: () => void }> = ({ onClickInc }) => {
  const {
    palette: { background },
  } = useTheme();

  return (
    <>
      <ListSubheader disableGutters className='mx-2' style={{ background: background.default }}>
        <div className='w-full flex'>
          <h6 className='text-xl flex-grow'>List</h6>
          <IconButton onClick={onClickInc}>
            <PlaylistAdd />
          </IconButton>
        </div>
      </ListSubheader>
      <Divider />
    </>
  );
};

interface DrawerItemProps {
  readonly selected: boolean;
  readonly index: number;
  readonly onClick: (index: number) => void;
}
const DrawerItem: React.FC<DrawerItemProps> = React.memo(({ selected, index, onClick }) => {
  return (
    <>
      <ListItem button onClick={() => onClick(index)}>
        <ListItemText className={selected ? 'text-red-500' : ''}>{index + 1}</ListItemText>
      </ListItem>
      <Divider />
    </>
  );
});

interface AppDrawerProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

export const AppDrawer: React.FC<AppDrawerProps> = (props) => {
  // TODO: localstorage
  const items: string[] = [];

  const onItemClick = useCallback(
    (index: number) => {
      props.onClose();
    },
    [props]
  );

  return (
    <nav>
      <Drawer anchor='left' {...props}>
        <List subheader={<SubHeader onClickInc={() => {}} />} className='w-48'>
          {items.map((_item, index) => (
            <DrawerItem key={index} selected={false} index={index} onClick={onItemClick} />
          ))}
        </List>
      </Drawer>
    </nav>
  );
};
