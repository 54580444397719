import React from 'react';
import { IconButton } from '@material-ui/core';
import { ExpandMore, NavigateNext } from '@material-ui/icons';

interface Props {
  readonly title: string;
  readonly open: boolean;
  readonly toggleOpenMe: () => void;
}

export const CollapseHeader: React.FC<Props> = ({ title, open, toggleOpenMe }) => {
  return (
    <>
      <div className='w-full flex items-center cursor-pointer' onClick={toggleOpenMe}>
        <IconButton>{open ? <ExpandMore /> : <NavigateNext />}</IconButton>
        <div>
          <h3 className='flex-grow text-lg font-bold'>{title}</h3>
        </div>
      </div>
    </>
  );
};
