import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { reducer as ermReducer } from './erm';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const rootReducer = combineReducers({
  erm: ermReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

type StoreState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;
