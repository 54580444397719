import React, { useCallback } from 'react';
import { List, ListItem, ListItemText, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/store';
import { changeNanoentities } from '../../store/erm';

interface NanoentityItemProps {
  readonly index: number;
  readonly nanoentity: string;
  readonly onChange: (index: number, value: string) => void;
}
const NanoentityItem: React.FC<NanoentityItemProps> = React.memo(({ index, nanoentity, onChange }) => {
  return (
    <ListItem>
      <ListItemText>
        <TextField value={nanoentity} onChange={(e) => onChange(index, e.target.value)} className='w-1/3' />
      </ListItemText>
    </ListItem>
  );
});

interface NanoEntitiesTableProps {
  readonly index: number;
}
export const NanoEntitiesList: React.FC<NanoEntitiesTableProps> = ({ index }) => {
  const dispatch = useDispatch();
  const { nanoentities } = useAppSelector((state) => state.erm.erm.entities)[index];
  const handleChangeNanoentities = useCallback(
    (nanoIndex: number, newValue: string) => {
      dispatch(
        changeNanoentities({
          entityIndex: index,
          nanoentityIndex: nanoIndex,
          newValue,
        })
      );
    },
    [dispatch, index]
  );

  return (
    <List>
      {nanoentities.map((x, index) => (
        <NanoentityItem key={index} index={index} nanoentity={x} onChange={handleChangeNanoentities} />
      ))}
    </List>
  );
};
