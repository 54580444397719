import React, { useCallback } from 'react';
import {
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useAppSelector } from '../../store/store';
import { useDispatch } from 'react-redux';
import { incrementRelations, changeRelation } from '../../store/erm';
import { Entity, Relation, RelationType, RelationTypeValues } from '../../interfaces/Erm';
import { CollapseHeader } from './CollapseHeader';

interface RelationsListItemSelectProps {
  readonly relationIndex: number;
  readonly type: 'origin' | 'destination';
  readonly entities: ReadonlyArray<Entity>;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly className?: string;
}
const RelationsListItemSelect: React.FC<RelationsListItemSelectProps> = React.memo(
  ({ relationIndex, type, entities, value, className, onChange }) => {
    const selectId = `select-${type}-${relationIndex}`;

    return (
      <FormControl className={className}>
        <InputLabel htmlFor={selectId}>{type}</InputLabel>
        <Select id={selectId} className='w-64' value={value} onChange={(e) => onChange(e.target.value as string)}>
          <MenuItem value=''>
            <em>none</em>
          </MenuItem>
          {entities.flatMap((entity) => [
            <ListSubheader>{entity.name}</ListSubheader>,
            ...entity.nanoentities.map((nano, index) => (
              <MenuItem value={`${entity.name}.${nano}`} key={index}>
                {nano}
              </MenuItem>
            )),
          ])}
        </Select>
      </FormControl>
    );
  }
);

interface RelationTypeSelectProps {
  readonly index: number;
  readonly value: RelationType;
  readonly onChange: (value: RelationType) => void;
}
const RelationTypeSelect: React.FC<RelationTypeSelectProps> = ({ index, value, onChange }) => {
  const id = `relation-type-select-${index}`;
  return (
    <FormControl className='ml-8'>
      <InputLabel htmlFor={id}>Relation type</InputLabel>
      <Select id={id} className='w-48' value={value} onChange={(e) => onChange(e.target.value as RelationType)}>
        {RelationTypeValues.map((relationType) => (
          <MenuItem key={relationType} value={relationType}>
            {relationType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface RelationsListItemProps {
  readonly relation: Relation;
  readonly index: number;
}
const RelationsListItem: React.FC<RelationsListItemProps> = React.memo(({ relation, index }) => {
  const entities = useAppSelector((state) => state.erm.erm.entities);
  const dispatch = useDispatch();

  const handleChangeRelationOrigin = useCallback(
    (value: string) => {
      dispatch(changeRelation({ index, relation: { origin: value } }));
    },
    [dispatch, index]
  );
  const handleChangeRelationDest = useCallback(
    (value: string) => {
      dispatch(changeRelation({ index, relation: { destination: value } }));
    },
    [dispatch, index]
  );
  const handleChangeRelationType = useCallback(
    (value: RelationType) => {
      dispatch(changeRelation({ index, relation: { type: value } }));
    },
    [dispatch, index]
  );

  return (
    <ListItem>
      <ListItemText>
        <div className='flex'>
          <RelationsListItemSelect
            relationIndex={index}
            type='origin'
            entities={entities}
            value={relation.origin}
            onChange={handleChangeRelationOrigin}
          />
          <RelationsListItemSelect
            relationIndex={index}
            type='destination'
            entities={entities}
            className='ml-4'
            value={relation.destination}
            onChange={handleChangeRelationDest}
          />
          <RelationTypeSelect index={index} value={relation.type} onChange={handleChangeRelationType} />
        </div>
      </ListItemText>
      <Divider />
    </ListItem>
  );
});

interface Props {
  readonly open: boolean;
  readonly toggleOpenMe: () => void;
}
export const RelationsList: React.FC<Props> = ({ open, toggleOpenMe }) => {
  const relations = useAppSelector((state) => state.erm.erm.relations);
  const dispatch = useDispatch();
  const handleIncrementRelations = () => {
    dispatch(incrementRelations());
  };

  return (
    <>
      <CollapseHeader title='Relations' open={open} toggleOpenMe={toggleOpenMe} />
      <Collapse in={open} timeout='auto' unmountOnExit className='w-full'>
        <List
          subheader={
            <div className='w-full flex justify-end'>
              <IconButton onClick={handleIncrementRelations}>
                <Add />
              </IconButton>
            </div>
          }
          className='mt-4'
        >
          {relations.map((relation, index) => (
            <RelationsListItem key={index} relation={relation} index={index} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
