/**
 * ERM 形式のドメインモデル
 *
 * 必須入力データ
 *
 * {@link https://ja.wikipedia.org/wiki/%E5%AE%9F%E4%BD%93%E9%96%A2%E9%80%A3%E3%83%A2%E3%83%87%E3%83%AB wikipedia}
 * {@link https://github.com/ServiceCutter/ServiceCutter/wiki/ERM GitHub Wiki}
 */
export interface Erm {
  /** モデル名 */
  name: string;
  /** モデル内で用いられるエンティティ */
  entities: Entity[];
  /** エンティティ間の関連 */
  relations: Relation[];
}

/**
 * エンティティ
 */
export interface Entity {
  /** エンティティ名 */
  name: string;
  /** エンティティの要素 */
  nanoentities: string[];
}

/**
 * 関連
 */
export interface Relation {
  /**
   * Entity.name を指定すること
   * @see Entity.name
   */
  origin: string;
  /**
   * Entity.name を指定すること
   * @see Entity.name
   */
  destination: string;
  /**
   * 関連形式
   *
   * - AGGREGATION (集約)
   * - COMPOSITION (コンポジション)
   * - INHERITANCE (継承)
   *
   * @example
   * // AGGREGATION
   * interface {origin} {
   *   .
   *   .
   *   .
   *   values: {destination}[];
   * }
   *
   * // COMPOSITION
   * interface {origin} {
   *   value: {destination};
   * }
   *
   * // INHERITANCE
   * interface {destination} extends {origin} {
   * }
   *
   */
  type: RelationType;
}

export const RelationTypeValues = ['AGGREGATION', 'COMPOSITION', 'INHERITANCE'] as const;
export type RelationType = typeof RelationTypeValues[number];
