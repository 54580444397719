import React, { PropsWithChildren, useState } from 'react';
import { ErmPage } from './pages/ErmPage';
import { UserRepsPage } from './pages/UserRepsPage';
import { CreateInputPage } from './pages/CreateInputPage';
import { AppDrawer } from './components/AppDrawer';
import { AppHeader } from './components/AppHeader';

interface TabPanelProps {
  readonly value: number;
  readonly selectedValue: number;
}
const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ children, value, selectedValue }) => {
  if (value !== selectedValue) return null;

  return (
    <div role='tabpanel' id={`tabpanel-${value}`} aria-labelledby={`tab-${value}`}>
      {children}
    </div>
  );
};

export const App: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);

  return (
    <div className='w-full'>
      <AppHeader selectedTabIndex={tabIdx} onChangeSelectedTabIndex={setTabIdx} onChangeDrawerOpen={setDrawerOpen} />
      <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <TabPanel value={0} selectedValue={tabIdx}>
        <ErmPage />
      </TabPanel>
      <TabPanel value={1} selectedValue={tabIdx}>
        <UserRepsPage />
      </TabPanel>
      <TabPanel value={2} selectedValue={tabIdx}>
        <CreateInputPage />
      </TabPanel>
    </div>
  );
};
