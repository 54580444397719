import React, { useState } from 'react';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Add, ExpandLess, ExpandMore } from '@material-ui/icons';
import { NanoEntitiesList } from './NanoEntitiesList';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/store';
import { changeEntityName, incrementEntities, incrementNanoentities } from '../../store/erm';
import { CollapseHeader } from './CollapseHeader';

interface ListItemProps {
  readonly entityName: string;
  readonly index: number;
}
const MyListItem: React.FC<ListItemProps> = React.memo(({ entityName, index }) => {
  const dispatch = useDispatch();
  const [openTable, setOpenTable] = useState(false);
  const handleChangeEntityName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeEntityName({ index, name: e.target.value }));
  };
  const handleIncrementNanoentities = () => {
    dispatch(incrementNanoentities({ index }));
    setOpenTable(true);
  };

  return (
    <ListItem>
      <ListItemIcon>
        <IconButton onClick={() => setOpenTable((x) => !x)}>{openTable ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </ListItemIcon>
      <ListItemText>
        <div className='w-full flex'>
          <div className='flex-grow'>
            <TextField value={entityName} onChange={handleChangeEntityName} className='w-1/3' />
          </div>
          <IconButton onClick={handleIncrementNanoentities}>
            <Add />
          </IconButton>
        </div>
        <Collapse in={openTable} timeout='auto' unmountOnExit className='ml-16'>
          <NanoEntitiesList index={index} />
        </Collapse>
        <Divider />
      </ListItemText>
    </ListItem>
  );
});

interface Props {
  readonly open: boolean;
  readonly toggleOpenMe: () => void;
}
export const EntitiesList: React.FC<Props> = ({ open, toggleOpenMe }) => {
  const dispatch = useDispatch();
  const entities = useAppSelector((state) => state.erm.erm.entities);

  return (
    <>
      <CollapseHeader title='Entities' open={open} toggleOpenMe={toggleOpenMe} />
      <Collapse in={open} timeout='auto' unmountOnExit className='w-full'>
        <List
          subheader={
            <div className='w-full flex justify-end'>
              <IconButton onClick={() => dispatch(incrementEntities())}>
                <Add />
              </IconButton>
            </div>
          }
        >
          {entities.map(({ name }, index) => (
            <MyListItem key={index} entityName={name} index={index} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
