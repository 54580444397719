import { Erm, Relation } from '../interfaces/Erm';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErmState {
  readonly erm: Erm;
}

type IncrementNanoentitiesArgs = PayloadAction<{
  readonly index: number;
}>;
type ChangeEntityNameArgs = PayloadAction<{
  readonly index: number;
  readonly name: string;
}>;
type ChangeNanoentitiesArgs = PayloadAction<{
  readonly entityIndex: number;
  readonly nanoentityIndex: number;
  readonly newValue: string;
}>;
type ChangeRelationArgs = PayloadAction<{
  readonly index: number;
  readonly relation: Partial<Relation>;
}>;

const ermSlice = createSlice({
  name: 'erm',
  initialState: { erm: { name: '', entities: [], relations: [] } } as ErmState,
  reducers: {
    incrementEntities: (state) => {
      state.erm.entities.push({
        name: '',
        nanoentities: [],
      });
    },
    incrementNanoentities: (state, { payload: { index } }: IncrementNanoentitiesArgs) => {
      state.erm.entities[index].nanoentities.push('');
    },
    changeEntityName: (state, { payload: { index, name } }: ChangeEntityNameArgs) => {
      state.erm.entities[index].name = name;
    },
    changeNanoentities: (state, { payload: { entityIndex, nanoentityIndex, newValue } }: ChangeNanoentitiesArgs) => {
      state.erm.entities[entityIndex].nanoentities[nanoentityIndex] = newValue;
    },
    incrementRelations: (state) => {
      state.erm.relations.push({
        origin: '',
        destination: '',
        type: 'AGGREGATION',
      });
    },
    changeRelation: (state, { payload: { index, relation } }: ChangeRelationArgs) => {
      state.erm.relations[index] = {
        ...state.erm.relations[index],
        ...relation,
      };
    },
  },
});

export const { reducer } = ermSlice;
export const {
  incrementEntities,
  incrementNanoentities,
  changeEntityName,
  changeNanoentities,
  incrementRelations,
  changeRelation,
} = ermSlice.actions;
