import React from 'react';
import { AppBar, IconButton, Tab, TabProps, Tabs, Toolbar } from '@material-ui/core';
import { GetApp, Menu } from '@material-ui/icons';

const a11yProps = (value: number): TabProps => ({
  id: `tab-${value}`,
  'aria-controls': `tabpanel-${value}`,
  value,
});

interface AppHeaderProps {
  readonly selectedTabIndex: number;
  readonly onChangeSelectedTabIndex: (index: number) => void;
  readonly onChangeDrawerOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
}

export const AppHeader: React.FC<AppHeaderProps> = ({
  selectedTabIndex,
  onChangeSelectedTabIndex,
  onChangeDrawerOpen,
}) => {
  return (
    <AppBar position='static'>
      <Toolbar>
        <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => onChangeDrawerOpen((x) => !x)}>
          <Menu />
        </IconButton>
        <h1 className='text-xl font-semibold flex-grow'>Service Cutter Input Creator</h1>
        <IconButton edge='start' color='inherit'>
          <GetApp />
        </IconButton>
      </Toolbar>
      <Tabs
        value={selectedTabIndex}
        onChange={(e, newIdx: number) => onChangeSelectedTabIndex(newIdx)}
        aria-label='tabs'
        variant='fullWidth'
      >
        <Tab label='Input ERM' {...a11yProps(0)} />
        <Tab label='Input UserReps' {...a11yProps(1)} />
        <Tab label='Display Input Data' {...a11yProps(2)} />
      </Tabs>
    </AppBar>
  );
};
