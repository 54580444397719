import React, { useCallback, useState } from 'react';
import { EntitiesList } from '../components/Erm/EntitiesList';
import { RelationsList } from '../components/Erm/RelationsList';

export const ErmPage: React.FC = React.memo(() => {
  const [open, setOpen] = useState<'entity' | 'relation' | null>(null);
  const handleOpenEntity = useCallback(() => {
    setOpen((cur) => (cur === 'entity' ? null : 'entity'));
  }, []);
  const handleOpenRelation = useCallback(() => {
    setOpen((cur) => (cur === 'relation' ? null : 'relation'));
  }, []);

  return (
    <div className='container mx-auto mt-4'>
      <EntitiesList open={open === 'entity'} toggleOpenMe={handleOpenEntity} />
      <RelationsList open={open === 'relation'} toggleOpenMe={handleOpenRelation} />
    </div>
  );
});
